import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Nav from "../components/Nav"
import Layout from "../components/layout";
import Seo from "../components/seo";

export const query = graphql`query ThankYouPageQuery {
  racingPigeon: file(relativePath: {eq: "racing-pigeon.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const ThankYou = ({ data }) => (
  <Layout>
    <Seo
      seo={{
        metaTitle: "Thank you",
        metaDescription: "It looks like you got to the thank you page",
      }}
    />
    <Nav />
      <main>
          <div className="mb-10 hero__wrapper hero__wrapper--content-left">
              <div className={"hero mt-8 md:mt-0 mx-auto max-w-screen-3xl"}>
                  <div className={"hero__text p-4 md:p-10 lg:p-16 xl:p-20 2xl:pl-48 md:absolute md:z-10 md:bg-secondary-bg md:w-3/5 xl:w-2/3"}>
                      <h1>Thank you for your message.</h1>
                      <p>Here is what happens now: the racing pigeon flies to our office at Adlershof in Berlin. It will knock on the window and wait for one of our team members to open the window. Once inside we will try to lure the pigeon onto a table to take the message off of her claw. She gets a good rub on her head and something to drink and eat. Now we will reply to your message.</p>
                  </div>
                  <div className="w-full max-w-screen-md ml-auto hero__image">
                      <GatsbyImage
                        image={data.racingPigeon.childImageSharp.gatsbyImageData}
                        className="min-h-56"
                        alt="TODO" />
                  </div>
              </div>
          </div>
      </main>
  </Layout>
);

export default ThankYou;
